import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FileUpload, Modal, ModalButtons } from '@components';
import { INotification } from '@interfaces';
import { services } from '@services';
import { Buttons } from './styled';

interface IProps {
  close: () => void;
  notification: INotification;
}

export const BulkLoad = ({ close, notification }: IProps) => {
  const controller = new AbortController();
  const [file, setFile] = useState<File | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const next = () => {
    if (file) {
      setIsProcessing(true);
      services.farm
        .bulkLoad(controller.signal, file)
        .then(() => {
          notification.success('El archivo se está procesando');
          setIsProcessing(false);
          close();
        })
        .catch(() => {
          notification.warning('El archivo no cumple con los requerimientos');
          setIsProcessing(false);
        });
    } else {
      notification.warning('Debes seleccionar un archivo');
    }
  };

  const buttons = (
    <Buttons>
      <Grid container>
        <Grid item xs={4} sm={6}>
          <Button
            size="large"
            component="a"
            href={`${process.env.PUBLIC_URL}/templates/farms.csv`}
            download
          >
            Descargar plantilla
          </Button>
        </Grid>
        <Grid item xs={8} sm={6}>
          <ModalButtons
            isProcessing={isProcessing}
            primaryAction={next}
            secondaryAction={close}
            primaryText="Continuar"
            secondaryText="Cancelar"
          />
        </Grid>
      </Grid>
    </Buttons>
  );

  return (
    <Modal title="Carga masiva" close={close} actions={buttons} maxWidth="sm">
      <FileUpload file={file} setFile={setFile} notification={notification} />
    </Modal>
  );
};
