import { design } from '@constants';
import styled, { css } from 'styled-components';

interface IWrapperProps {
  selectedFile: boolean;
}

export const Wrapper = styled.div`
  padding: 5px 0 15px;

  label {
    text-align: center;
    display: block;
    padding: 20px 20px 25px;
    border-radius: 10px;
    ${(props: IWrapperProps) =>
      props.selectedFile
        ? css`
            color: ${design.colors.green};
            border: solid 2px ${design.colors.green};
            background-color: ${design.colors.lightGreen} !important;
          `
        : css`
            color: ${design.colors.primary};
            border: solid 2px ${design.colors.mediumGray};
            background-color: ${design.colors.ultralightGray} !important;
          `}
  }

  label:hover {
    ${(props: IWrapperProps) =>
      props.selectedFile
        ? css`
            border: solid 2px ${design.colors.green};
            background-color: ${design.colors.lightGreen} !important;
          `
        : css`
            border: solid 2px ${design.colors.primary};
            background-color: ${design.colors.ultralightPrimary} !important;
            svg {
              font-size: 50px;
            }
          `}
  }
`;

export const Icon = styled.div`
  height: 70px;
  display: flex;
  svg {
    margin: auto;
    transition: 0.2s;
    font-size: 40px;
  }
`;

export const Title = styled.div``;

export const Subtitle = styled.div`
  color: ${design.colors.darkGray};
  font-weight: normal;
  font-size: 12px;
`;
