import { axiosInstance } from './axios';
import { api } from '@constants';
import { IFarm } from '@interfaces';

const all = (signal: AbortSignal) => {
  return axiosInstance.get(api.farms, { signal: signal });
};

const create = (signal: AbortSignal, farm: IFarm) => {
  return axiosInstance.post(api.farms, farm, { signal: signal });
};

const update = (signal: AbortSignal, farm: IFarm) => {
  return axiosInstance.put(api.farms, farm, { signal: signal });
};

const del = (signal: AbortSignal, id: number) => {
  return axiosInstance.delete(`${api.farms}/${id}`, { signal: signal });
};

const bulkLoad = (signal: AbortSignal, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return axiosInstance.post(`${api.farms}/bulk-load`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const farm = {
  all,
  create,
  update,
  del,
  bulkLoad,
};
