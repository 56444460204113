import { axiosInstance } from './axios';
import { api } from '@constants';
import { INewEquipment, IUpdateEquipmentState } from '@interfaces';

const all = (signal: AbortSignal) => {
  return axiosInstance.get(api.equipments, { signal: signal });
};

const create = (signal: AbortSignal, data: INewEquipment) => {
  return axiosInstance.post(api.equipments, data, { signal: signal });
};

const update = (signal: AbortSignal, data: INewEquipment) => {
  return axiosInstance.put(api.equipments, data, { signal: signal });
};

const updateState = (signal: AbortSignal, data: IUpdateEquipmentState) => {
  return axiosInstance.put(`${api.equipments}/state`, data, { signal: signal });
};

const del = (signal: AbortSignal, id: number) => {
  return axiosInstance.delete(`${api.equipments}/${id}`, { signal: signal });
};

const bulkLoad = (signal: AbortSignal, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return axiosInstance.post(`${api.equipments}/bulk-load`, formData, {
    signal: signal,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const equipment = {
  all,
  create,
  update,
  updateState,
  del,
  bulkLoad,
};
