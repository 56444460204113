import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TaskIcon from '@mui/icons-material/Task';
import { INotification } from '@interfaces';
import { Wrapper, Icon, Title, Subtitle } from './styled';

interface IProps {
  file: File | null;
  setFile: (value: File | null) => void;
  notification: INotification;
}

export const FileUpload = ({ file, setFile, notification }: IProps) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (file) {
      setTitle(`Se ha seleccionado el archivo ${file.name}`);
      if (file.size >= 1024) {
        setDescription(`Tamaño del archivo ${(file.size / 1024).toFixed(0)} KB`);
      } else {
        setDescription(`Tamaño del archivo ${file.size} Bytes`);
      }
    } else {
      setTitle('Haz clic para seleccionar el archivo');
      setDescription('Se aceptan archivos CSV con 1 MB de tamaño máximo');
    }
  }, [file]);

  const beforeUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFile = event.target.files[0];
      if (newFile) {
        if (newFile.size / 1024 > 1024) {
          notification.warning('El archivo es demasiado grande');
          setFile(null);
        } else {
          setFile(newFile);
        }
        event.target.value = '';
      }
    }
  };

  return (
    <Wrapper selectedFile={file}>
      <Button variant="outlined" size="large" component="label">
        <Icon>{file ? <TaskIcon /> : <UploadFileIcon />}</Icon>
        <Title>{title}</Title>
        <Subtitle>{description}</Subtitle>
        <input hidden accept=".csv" type="file" onChange={beforeUpload} />
      </Button>
    </Wrapper>
  );
};
